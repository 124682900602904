/* global axios */
import ApiClient from '../ApiClient';

class SubscriptionAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true, apiVersion: 'v2' });
  }

  checkout() {
    return axios.get(`${this.url}subscriptions/checkout`, {});
  }

  subscription() {
    return axios.get(`${this.url}subscriptions`);
  }

  getLimits() {
    return axios.get(`${this.url}limits`);
  }

  getInvoices() {
    return axios.get(`${this.url}subscriptions/invoices`);
  }
}

export default new SubscriptionAPI();
