import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue'; import FluentIcon
from 'shared/components/FluentIcon/Index.vue';
<template>
  <div>
    <!-- Old Version Banner -->
    <div
      v-if="version === 'old'"
      class="flex items-center justify-center h-12 gap-4 px-4 py-3 text-xs text-white banner dark:text-white"
      :class="bannerClasses"
    >
      <span class="banner-message">
        {{ bannerMessage }}
        <a
          v-if="hrefLink"
          :href="hrefLink"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {{ hrefLinkText }}
        </a>
      </span>
      <div class="actions">
        <woot-button
          v-if="hasActionButton"
          size="tiny"
          :icon="actionButtonIcon"
          :variant="actionButtonVariant"
          color-scheme="primary"
          class-names="banner-action__button"
          @click="onClick"
        >
          {{ actionButtonLabel }}
        </woot-button>
        <woot-button
          v-if="hasCloseButton"
          size="tiny"
          :color-scheme="colorScheme"
          icon="dismiss-circle"
          class-names="banner-action__button"
          @click="onClickClose"
        >
          {{ $t('GENERAL_SETTINGS.DISMISS') }}
        </woot-button>
      </div>
    </div>

    <!-- New Version Banner -->
    <div
      v-else-if="version === 'new'"
      :class="bannerTypeClass"
      class="w-full px-4 py-3"
    >
      <div class="flex items-center gap-2">
        <fluent-icon
          v-if="icon || emoji"
          class="icon"
          :icon="icon"
          :icon-type="iconType"
          :icon-size="iconSize"
        />
        <h2>{{ bannerMessage }}</h2>
      </div>
      <a v-if="!onClick" class="action" :href="hrefLink">
        <button @click="onClick">{{ actionButtonLabel }}</button>
      </a>

      <button v-else class="action" @click="onClick">
        {{ actionButtonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerMessage: {
      type: String,
      default: '',
    },
    hrefLink: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    hrefLinkText: {
      type: String,
      default: '',
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonVariant: {
      type: String,
      default: '',
    },
    actionButtonLabel: {
      type: String,
      default: '',
    },
    actionButtonIcon: {
      type: String,
      default: 'arrow-right',
    },
    colorScheme: {
      type: String,
      default: '',
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
    version: {
      type: String,
      default: 'old', // default to 'old' version
    },
    bannerType: {
      type: String,
      default: 'info', // default type for new version
    },
  },
  computed: {
    bannerClasses() {
      const classList = [this.colorScheme];
      if (this.hasActionButton || this.hasCloseButton) {
        classList.push('has-button');
      }

      return classList;
    },
    bannerTypeClass() {
      switch (this.bannerType) {
        case 'info':
          return 'bg-peach-300 text-peach-800 dark:bg-peach-800 dark:text-peach-300 warning';
        case 'warning':
          return 'warning bg-yellow-200 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-200';
        case 'error':
          return 'warning bg-red-200 text-red-800 dark:bg-red-800 dark:text-red-200';
        case 'danger':
          return 'warning bg-red-600 text-white dark:bg-red-700 dark:text-red-200';
        default:
          return 'warning bg-gray-200 text-gray-800 dark:bg-gray-800 dark:text-gray-200';
      }
    },
    iconSrc() {
      // Check if emoji or icon is provided
      if (this.emoji || this.icon) {
        return this.emoji ?? this.icon;
      }

      // Fall back to the banner type icons
      switch (this.bannerType) {
        case 'info':
          return 'info';
        case 'warning':
          return 'warning';
        case 'error':
          return 'error-circle';
        case 'danger':
          return 'error-circle';
        default:
          return 'info'; // Default fallback
      }
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onClickClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  &.primary {
    @apply bg-woot-500 dark:bg-woot-500;
    .banner-action__button {
      @apply bg-woot-600 dark:bg-woot-600 border-none text-white;

      &:hover {
        @apply bg-woot-700 dark:bg-woot-700;
      }
    }
  }

  &.secondary {
    @apply bg-slate-200 dark:bg-slate-300 text-slate-800 dark:text-slate-800;
    a {
      @apply text-slate-800 dark:text-slate-800;
    }
  }

  &.alert {
    @apply bg-red-500 dark:bg-red-500;
    .banner-action__button {
      @apply bg-red-700 dark:bg-red-700 border-none text-white dark:text-white;

      &:hover {
        @apply bg-red-800 dark:bg-red-800;
      }
    }
  }

  &.warning {
    @apply bg-yellow-500 dark:bg-yellow-500 text-yellow-500 dark:text-yellow-500;
    a {
      @apply text-yellow-500 dark:text-yellow-500;
    }
  }

  &.gray {
    @apply text-black-500 dark:text-black-500;
    .banner-action__button {
      @apply text-white dark:text-white;
    }
  }

  a {
    @apply ml-1 underline text-white dark:text-white text-xs;
  }

  .banner-action__button {
    ::v-deep .button__content {
      @apply whitespace-nowrap;
    }
  }

  .banner-message {
    @apply flex items-center;
  }

  .actions {
    @apply flex gap-1 right-3;
  }
}

/* New version styles */
.warning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  height: 3.563rem;
}
.warning .icon {
  display: flex;
}
.warning .icon figure {
  height: 1.25rem;
  width: 1.25rem;
}
.warning h2 {
  line-height: 1.05rem;
  font-weight: 500;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  width: 54vw;
}
.warning .action,
.warning .action button {
  width: 6.875rem;
  height: 2.063rem;
  background: #fff;
  color: #de8144;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  line-height: 1.05rem;
  font-weight: 500;
  font-size: 0.875rem;
}
</style>
