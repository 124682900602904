<template>
  <banner
    v-if="shouldShowBanner"
    color-scheme="primary"
    :banner-message="billingBannerMessage"
    :action-button-label="actionButtonMessage"
    has-action-button
    banner-type="info"
    icon="info"
    version="new"
    icon-type="filled"
    action-button-variant="primary"
    @click="routeToBilling"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';

export default {
  components: { Banner },
  mixins: [adminMixin, accountMixin],
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      plan: 'accounts/getPlan',
    }),
    // bannerMessage() {
    //   return this.$t('GENERAL_SETTINGS.PAYMENT_PENDING');
    // },

    billingBannerMessage() {
      if (this.subscription.expiring_soon && this.subscription.on_trial) {
        return `Your free trial expires in ${
          this.subscription.days_remaining || 0
        } days. Select a plan and billing details to continue using Ruutchat`;
      }
      if (this.subscription.expiring_soon && !this.subscription.on_trial) {
        return `Your Subscription expires in ${
          this.subscription.days_remaining || 0
        } days. Renew your plan to avoid service distruption`;
      }

      return `Your currently don't have an active subscription, Purchase a plan to continue using Ruutchat`;
    },

    billingBannerLinkText() {
      return 'Goto Billing';
    },
    subscription() {
      return this.plan?.subscription || {};
    },

    actionButtonMessage() {
      return this.$t('GENERAL_SETTINGS.OPEN_BILLING');
    },
    shouldShowBanner() {
      if (!this.isAdmin) {
        return false;
      }

      return this.isPaymentPending();
    },
  },
  mounted() {
    if (!this.plan) {
      this.fetchSubscription();
    }
  },
  methods: {
    routeToBilling() {
      this.$router.push({
        name: 'billing_settings_index',
        params: { accountId: this.accountId },
      });
    },

    async fetchSubscription() {
      await this.$store.dispatch('accounts/subscription');
    },
    isPaymentPending() {
      return (
        this.subscription.expiring_soon || !this.subscription.is_active || false
      );
    },
    // getSubscriptionInfo() {
    //   const account = this.getAccount(this.accountId);
    //   if (!account) return EMPTY_SUBSCRIPTION_INFO;
    //
    //   const { custom_attributes: subscription } = account;
    //   if (!subscription) return EMPTY_SUBSCRIPTION_INFO;
    //
    //   const { subscription_status: status, subscription_ends_on: endsOn } =
    //     subscription;
    //
    //   return { status, endsOn: new Date(endsOn) };
    // },
  },
};
</script>
